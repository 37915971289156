<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Tarieven</h2>
          <p>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">Consult</th>
                  <th class="text-left">
                    &plusmn; <v-icon small>mdi-clock</v-icon>
                  </th>
                  <th class="text-left">Prijs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Intake</strong></td>
                  <td>45min</td>
                  <td>€104,-</td>
                </tr>
                <tr>
                  <td><strong>Normaal</strong></td>
                  <td>45min</td>
                  <td>€104,-</td>
                </tr>
                <tr>
                  <td><strong>Kort</strong></td>
                  <td>30min</td>
                  <td>€73,-</td>
                </tr>
              </tbody>
            </v-simple-table>
          </p>
          <h3>Betaling</h3>
          <p>
            Het consult-tarief dient direct per pin of contant te worden voldaan
            na de behandeling, waarna u een betalingsbewijs krijgt ter indiening
            bij uw verzekering. De meeste zorgverzekeraars vergoeden
            (grotendeels) deze consulten indien u aanvullend verzekerd bent.
            Raadpleeg daarom altijd eerst uw zorgverzekeraar, zodat u niet voor
            verrassingen komt te staan.
          </p>
          <h3>Verwijzing</h3>
          <p>
            U heeft geen verwijzing nodig voor een behandeling bij de osteopaat.
          </p>
          <h3>Erkenning en registratie</h3>
          <p>
            Wij zijn erkende osteopaten en staan geregistreerd bij het
            Nederlands Register voor Osteopathie (NRO).
          </p>
          <h3>Annuleren afspraak</h3>
          <p>
            Indien u bij verhindering tenminste 24 uur van te voren afzegt,
            wordt de gereserveerde tijd niet in rekening gebracht.
          </p>
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../components/Container.vue";

export default {
  name: "Tarieven",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
h3 {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}
</style>
